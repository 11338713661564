import { GetToolsType, ToolBaseType } from '@customTypes/tool'

type TotalParams = {
  value: number
  quantity: number
  percentage: number
  hasAccessory: boolean
}

export const getTotal = (params: TotalParams) => {
  const { value, quantity, percentage, hasAccessory: hasAccessory } = params

  let result = value * quantity

  if (hasAccessory) {
    result *= 1 + percentage / 100
  }

  return Number(result.toFixed(2))
}

type SearchParams<T> = {
  params: GetToolsType
  data?: T[]
}

export const onFilterTool = <T extends ToolBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
  } = params

  if (!query || !field || !data) {
    return data
  }

  if (['name', 'description'].includes(field)) {
    const keyField = field as 'name' | 'description'
    return data.filter((item) => item[keyField].toLowerCase().includes(query.toLowerCase()))
  }

  if (field === 'uid') {
    return data.filter((item) => item.uid.startsWith(query))
  }

  return data
}

export const filterToolsAttachments = (tools: ToolBaseType[]) => {
  const attachmentMap = new Map<string, ToolBaseType>()

  for (const tool of tools) {
    if (tool.attachments.length > 0) {
      const keyAttachment = tool.attachments.join('')
      attachmentMap.set(keyAttachment, tool)
    }
  }

  return Array.from(attachmentMap.values())
}
