import React from 'react'
import {
  List,
  Text,
  Title,
  Table,
  Button,
  Divider,
  Container,
  ActionIcon,
  Fieldset,
} from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { IconCubePlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  ReceiptType,
  GetReceiptsType,
  ReceiptBaseType,
  DeleteRawMaterialsFromReceiptType,
} from '@customTypes/receipt'
import { ModalFormType } from '@customTypes/modal'
import { GetRawMaterialsType, RawMaterialBaseType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { useParams } from '@hooks/params'

import { buildSelectOptions, filterSelect } from '@utils/form'
import { ErrorCodes, ErrorService } from '@utils/error'
import { onFilterRawMaterial } from '@utils/rawMaterial'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import RawMaterialPreview from '@components/rawMaterial/preview'
import ConfirmationModal from '@components/shared/confirmationModal'
import ReceiptForm, { ReceiptFormType } from '@components/receipt/form'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

type QueryKeysType = (string | null)[]

const Receipts = () => {
  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalReceiptForm, setModalReceiptForm] = React.useState<ModalFormType<ReceiptFormType>>({
    opened: false,
  })

  const [modalRawMaterialPreview, setRawMaterialModalPreview] = React.useState<
    ModalFormType<RawMaterialBaseType>
  >({
    opened: false,
  })

  const [modalRawMaterialDelete, setModalRawMaterialDelete] = React.useState<
    ModalFormType<ReceiptType['rawMaterials']>
  >({
    opened: false,
  })

  const [modalReceiptDelete, setModalReceiptDelete] = React.useState<ModalFormType<ReceiptType>>({
    opened: false,
  })

  const [selectedRows, handlers] = useListState<string>([])

  const [receiptID, setReceiptID] = React.useState<string | null>(null)

  const [receiptIDs, setReceiptIDs] = React.useState<string[]>([])

  const [, setParams] = useParams<GetReceiptsType>({
    queryKey: QueryKeys.GET_RECEIPTS_KEY,
    initialParams: {},
  })

  const [paramsRawMaterial, setParamsRawMaterial] = useParams<GetRawMaterialsType>({
    queryKey: QueryKeys.GET_MATERIALS_KEY,
    initialParams: {},
  })

  const receiptsQuery = useQuery<ReceiptBaseType[], ErrorService, ReceiptBaseType[]>({
    initialData: [],
    queryKey: [QueryKeys.GET_RECEIPTS_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getReceipts({})
      return response
    },
  })

  const receiptQuery = useQuery<ReceiptType, ErrorService, ReceiptType, QueryKeysType>({
    refetchOnMount: false,
    enabled: Boolean(receiptID),
    queryKey: [QueryKeys.GET_RECEIPT_KEY, receiptID],
    queryFn: async ({ queryKey }) => {
      if (!queryKey[1]) {
        throw ErrorService.get(ErrorCodes.INVALID_PARAMS)
      }

      const response = await DataRepo.constructionEntitiesService.getReceiptById(queryKey[1])

      return response
    },
  })

  const receiptsDeleteMutation = useMutation<void, ErrorService, string>({
    mutationFn: async (uid) => {
      const response = await DataRepo.constructionEntitiesService.deleteReceipt(uid)

      setParams({})

      setReceiptID(null)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar la receta',
        })
      }

      setModalReceiptDelete({ opened: false })

      notifications.show({
        color: 'green',
        title: 'Receta eliminada',
        message: 'Receta eliminada correctamente',
      })
    },
  })

  const removeRMFromReceiptMutation = useMutation<
    void,
    ErrorService,
    DeleteRawMaterialsFromReceiptType
  >({
    mutationFn: async (data) => {
      const response =
        await DataRepo.constructionEntitiesService.deleteRawMaterialsFromReceipt(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_RECEIPT_KEY, receiptID],
        refetchType: 'all',
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_RECEIPTS_KEY,
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar las materias primas',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Materias primas eliminada',
        message: 'Materias primas eliminada correctamente',
      })

      setModalRawMaterialDelete({ opened: false })
      handlers.setState([])
    },
  })

  React.useEffect(() => {
    if (!receiptsQuery.data) return

    setReceiptIDs(receiptsQuery.data.map((item) => item.uid))
  }, [receiptsQuery.data])

  React.useEffect(() => {
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoFilterRawMaterial = React.useMemo(
    () =>
      onFilterRawMaterial<ReceiptType['rawMaterials'][0]>({
        data: receiptQuery.data?.rawMaterials,
        params: paramsRawMaterial,
      }),
    [paramsRawMaterial, receiptQuery.data?.rawMaterials],
  )

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Recetas</Text>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() =>
              setModalReceiptForm({
                opened: true,
                data: {
                  receiptIDs,
                },
              })
            }
          >
            Agregar
          </Button>
        </div>

        <Input
          searchable
          data={buildSelectOptions({
            data: receiptsQuery.data,
            value: 'uid',
            label: (item) => `${item.uid} - ${item.name}`,
          })}
          filter={(input) =>
            filterSelect({
              limit: 5,
              search: input.search,
              options: input.options,
            })
          }
          loading={isLoadingOrRefetchQuery(receiptsQuery)}
          nothingFoundMessage="No se encontraron recetas"
          placeholder="Seleccione una receta"
          rightSection={<IconSearch size={18} />}
          typeInput="select"
          value={receiptID}
          onChange={(v) => setReceiptID(v)}
        />

        <Divider className="cd-my-[1rem]" />

        {receiptQuery.data && (
          <div className="cd-flex cd-flex-col cd-gap-[1rem] md:cd-flex-row">
            <div className="md:cd-basis-[80%]">
              <div className="cd-flex cd-flex-col md:cd-gap-y-[0.25rem] md:cd-justify-between md:cd-items-start md:cd-align-top">
                <Title className="cd-text-xl">
                  <Text inherit c="dimmed" component="span">
                    {receiptQuery.data.uid}
                  </Text>
                  {' - '}
                  {receiptQuery.data.name}
                </Title>
              </div>
            </div>

            {selectedRows.length === 0 && (
              <div className="cd-flex cd-flex-col md:cd-flex-row cd-grow cd-justify-end cd-gap-x-[0.5rem]">
                {/* <div className="md:cd-basis-[80%]" /> */}
                <div className="md:cd-basis-[20%] cd-flex md:cd-flex-row cd-gap-[0.5rem] cd-grow cd-justify-end">
                  <Button
                    fullWidth
                    color="blue"
                    disabled={!receiptQuery.data || selectedRows.length > 0}
                    onClick={() => {
                      if (!receiptQuery.data) return

                      setModalReceiptForm({
                        opened: true,
                        data: {
                          receipt: receiptQuery.data,
                          receiptIDs,
                        },
                      })
                    }}
                  >
                    Editar receta
                  </Button>
                  <ActionIcon
                    color="red"
                    disabled={!receiptQuery}
                    size={36}
                    variant="light"
                    onClick={() =>
                      setModalReceiptDelete({
                        opened: true,
                        data: receiptQuery.data,
                      })
                    }
                  >
                    <IconTrash size={18} />
                  </ActionIcon>
                </div>
              </div>
            )}
          </div>
        )}

        {receiptQuery.data && (
          <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-gap-x-[1rem] md:cd-flex-row md:cd-gap-y-0 md:cd-w-full">
            <Fieldset className="md:cd-w-1/2" legend="Descripción del item" variant="filled">
              <Text className="cd-text-base">{receiptQuery.data.description}</Text>
            </Fieldset>

            <Fieldset className="md:cd-w-1/2" legend="Descripción para proforma" variant="filled">
              <Text className="cd-text-base">
                {receiptQuery.data.descriptionProforma || 'Sin descripción para proforma'}
              </Text>
            </Fieldset>
          </div>
        )}

        <TableCustom<ReceiptType['rawMaterials'][0]>
          columns={buildColumns()}
          data={memoFilterRawMaterial}
          extraRows={
            <Table.Tr className="cd-relative cd-cursor-default" key="extra-row">
              <Table.Td colSpan={6} />
              <Table.Td className="cd-font-bold" colSpan={1}>
                Total
              </Table.Td>
              <Table.Td className="cd-text-right" colSpan={1}>
                <NumberFormat
                  className="cd-font-bold"
                  value={memoFilterRawMaterial?.reduce(
                    (acc, item) => acc + item.quantity * item.value,
                    0,
                  )}
                />
              </Table.Td>
            </Table.Tr>
          }
          keyId="uid"
          loading={isLoadingOrRefetchQuery(receiptQuery)}
          loadingMessage="Cargando materias primas"
          minWidth={minWidth}
          noDataMessage={
            receiptID
              ? 'No se encontraron materias primas'
              : 'Seleccione una receta para ver sus materias primas'
          }
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            if (!receiptQuery.data) return

            const data = receiptQuery.data.rawMaterials.filter((item) => ids.includes(item.uid))
            if (!data.length) return

            setModalRawMaterialDelete({ opened: true, data })
          }}
          onDetail={(data) => {
            setRawMaterialModalPreview({ opened: true, data })
          }}
          onSearch={(query, field) => setParamsRawMaterial({ query, field })}
        />
      </div>

      <ReceiptForm
        modal
        data={modalReceiptForm.data}
        open={modalReceiptForm.opened}
        size="85%"
        onClose={() => setModalReceiptForm({ opened: false })}
        onSubmitted={(receipt) => {
          setParams({})

          if (receipt) {
            setReceiptID(receipt.uid)
          }
        }}
      />

      <RawMaterialPreview
        modal
        data={modalRawMaterialPreview.data}
        open={modalRawMaterialPreview.opened}
        onClose={() => setRawMaterialModalPreview({ opened: false })}
      />

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={false}
        opened={modalReceiptDelete.opened}
        title="Eliminar receta"
        onCancel={() => setModalReceiptDelete({ opened: false })}
        onConfirm={() => {
          if (!modalReceiptDelete.opened || !modalReceiptDelete.data) return

          receiptsDeleteMutation.mutate(modalReceiptDelete.data.uid)
        }}
      >
        <Text className="cd-text-base">
          ¿Estás seguro que deseas eliminar la receta{' '}
          <Text inherit className="cd-text-base cd-font-bold" component="span">
            {modalReceiptDelete.data?.uid} - {modalReceiptDelete.data?.name}
          </Text>
          ?
        </Text>
      </ConfirmationModal>

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={isLoadingMutation(removeRMFromReceiptMutation)}
        opened={modalRawMaterialDelete.opened}
        title="Eliminar materia prima"
        onCancel={() => setModalRawMaterialDelete({ opened: false })}
        onConfirm={() => {
          if (!modalRawMaterialDelete.data || !receiptQuery.data) return

          removeRMFromReceiptMutation.mutate({
            receiptID: receiptQuery.data?.uid,
            rawMaterialIDs: modalRawMaterialDelete.data.map((item) => item.uid),
          })
        }}
      >
        {modalRawMaterialDelete.data?.length === 1 && (
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la materia prima{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalRawMaterialDelete.data[0].uid} - {modalRawMaterialDelete.data[0].name}
            </Text>
            ?
          </Text>
        )}
        {modalRawMaterialDelete.data && modalRawMaterialDelete.data.length > 1 && (
          <React.Fragment>
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar las siguientes materias primas?
            </Text>
            <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
              {modalRawMaterialDelete.data.map((item) => (
                <List.Item key={item.uid}>
                  <Text className="cd-text-base">
                    {item.uid} - {item.name}
                  </Text>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        )}
      </ConfirmationModal>
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'uid', label: 'ID', width: '5%', type: 'text', searchable: true },
      { key: 'name', label: 'Nombre', width: '10%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '20%', type: 'text', searchable: true },
      { key: 'measure', label: 'Medida', width: '5%', type: 'text', searchable: true },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '5%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ quantity }) => <NumberFormat decimals={0} prefix="" value={quantity} />,
      },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '5%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
      {
        key: 'calc',
        label: 'Total ($)',
        width: '5%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: (item) => <NumberFormat value={item.quantity * item.value} />,
      },
    ] as GenericColumnType<ReceiptType['rawMaterials'][0]>[]
  }
}

export default Receipts
