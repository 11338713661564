import React from 'react'
import { useListState } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { Button, Loader, Modal, Text } from '@mantine/core'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  CommitToolBaseType,
  CreateCommitToolType,
  UpdateCommitToolType,
  CreateCommitToolSchema,
} from '@customTypes/commitTool'
import { ModalProps } from '@customTypes/modal'
import { ToolBaseType } from '@customTypes/tool'

import QueryKeys from '@constants/queryKeys'
import { CommitAction } from '@constants/commits'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import { Dropzone } from '@components/shared/dropzone'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { WarehouseToolType } from '@customTypes/warehouseTool'
import { ellipsis } from '@utils/string'

type RMItem = WarehouseToolType['tools'][0] & { stock: number }

export type CommitToolFormType = {
  warehouseToolID: string
  warehouseToolName: string
  toolsWarehouseTool: RMItem[]
  commitTool?: CommitToolBaseType
}

const INITIAL: CreateCommitToolType['commit'] = {
  message: '',
  action: 'consume',
  attachment: null,
  tools: [],
}

const CommitToolForm = (props: ModalProps<CommitToolFormType>) => {
  const { open, modal, data = {} as CommitToolFormType, size = 'lg', onClose: outerOnClose } = props

  const [confirmExit, setConfirmExit] = React.useState(false)

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [selectedRows, handlers] = useListState<string>([])

  const formCommitTool = useForm<CreateCommitToolType['commit']>({
    initialValues: INITIAL,
    validate: zodResolver(CreateCommitToolSchema.shape.commit),
  })

  const commitToolCreateMutation = useMutation<
    CommitToolBaseType,
    ErrorService,
    CreateCommitToolType
  >({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.createCommitTool(data)

      await queryClient.invalidateQueries({
        predicate: (query) =>
          [QueryKeys.GET_COMMIT_TOOLS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_TOOL_KEY, warehouseToolID],
        refetchType: 'all',
        exact: true,
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear el registro',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Registro creado correctamente',
      })

      onClose?.()
    },
  })

  const commitToolUpdateMutation = useMutation<void, ErrorService, UpdateCommitToolType>({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.updateCommitTool(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_COMMIT_TOOLS_KEY, data.warehouseToolID],
        refetchType: 'all',
        exact: true,
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar el registro',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Registro actualizado correctamente',
      })

      onClose?.()
    },
  })

  const toolsQuery = useQuery<ToolBaseType[], ErrorService>({
    initialData: [],
    queryKey: [QueryKeys.GET_TOOLS_KEY],
    queryFn: async () => {
      return await DataRepo.constructionEntitiesService.getTools({
        orderDir: 'asc',
      })
    },
  })

  React.useEffect(() => {
    if (open && data?.commitTool) {
      formCommitTool.setValues(data.commitTool)
    } else if (!open) {
      formCommitTool.setValues(INITIAL)
      handlers.setState([])
    }
    formCommitTool.resetDirty()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.commitTool, open])

  const isLoading = isLoadingMutation(commitToolCreateMutation, commitToolUpdateMutation)

  const { tools, action } = formCommitTool.getValues()

  const { warehouseToolID, toolsWarehouseTool } = data

  const sourceTools = action === 'refill' ? parseTools(toolsQuery.data) : toolsWarehouseTool

  const content = warehouseToolID && (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formCommitTool.onSubmit((values) => {
        if (!warehouseToolID) return

        if (data.commitTool) {
          commitToolUpdateMutation.mutate({
            warehouseToolID,
            current: values,
            previous: data.commitTool,
          })
        } else {
          commitToolCreateMutation.mutate({
            warehouseToolID,
            commit: values,
          })
        }
      })}
    >
      <Input
        data={Object.values([CommitAction.consume, CommitAction.refill])}
        label="Tipo de acción"
        typeInput="segmentedControl"
        {...formCommitTool.getInputProps('action')}
        onBlur={() => formCommitTool.validateField('action')}
        onChange={(value) => {
          formCommitTool.setFieldValue('action', value as CommitToolBaseType['action'], {
            forceUpdate: true,
          })
          formCommitTool.setFieldValue('tools', [], { forceUpdate: true })
        }}
      />

      <Input
        autosize
        label="Mensaje"
        maxRows={4}
        minRows={2}
        placeholder="Describa la acción realizada"
        typeInput="textarea"
        {...formCommitTool.getInputProps('message')}
        onBlur={() => formCommitTool.validateField('message')}
      />

      <Dropzone
        files={formCommitTool.values.attachment ? [formCommitTool.values.attachment] : []}
        label="Adjuntar archivo"
        maxFiles={1}
        multiple={false}
        onDrop={(files) => formCommitTool.setFieldValue('attachment', files[0])}
      />

      <Input
        clearable
        searchable
        data={buildSelectOptions<RMItem>({
          data: sourceTools.filter((item) => !tools.some((rm) => rm.uid === item.uid)),
          value: 'uid',
          label: (item) => `${item.name} (${ellipsis(item.description)})`,
        })}
        filter={(input) =>
          filterSelect({
            limit: 5,
            search: input.search,
            options: input.options,
          })
        }
        nothingFoundMessage={
          <div>
            <Text c="dimmed" className="cd-text-sm">
              {toolsQuery.data.length
                ? 'No se pueden agregar materias primas existentes'
                : 'No hay materias primas'}
            </Text>
          </div>
        }
        placeholder="Escribe para buscar materia prima existente"
        rightSection={
          isLoadingOrRefetchQuery(toolsQuery) ? <Loader size={18} /> : <IconSearch size={18} />
        }
        typeInput="select"
        value={undefined}
        withCheckIcon={false}
        onChange={(value) => {
          const tool = sourceTools.find((item) => item.uid === value)

          if (!tool) return

          formCommitTool.insertListItem('tools', {
            ...tool,
            quantity: 1,
          })
        }}
      />

      <TableCustom<CommitToolBaseType['tools'][0]>
        columns={buildColumns()}
        data={tools}
        keyId="uid"
        minWidth={minWidth}
        noDataMessage="No se han agregado materias primas"
        selectedRows={selectedRows}
        onChangeSelected={handlers}
        onDelete={(ids) => {
          const filtered = tools.filter((item) => !ids.includes(item.uid))

          formCommitTool.setFieldValue('tools', filtered, { forceUpdate: true })
          handlers.setState([])
        }}
      />

      <Button loaderProps={{ type: 'dots' }} loading={isLoading} type="submit">
        {data.commitTool ? 'Editar' : 'Crear'} registro
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={`${data.commitTool ? 'Editar' : 'Nuevo'} registro de bodega ${data.warehouseToolName}`}
        onClose={() => {
          if (formCommitTool.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function parseTools(tools: ToolBaseType[]) {
    const toolItems: RMItem[] = []

    for (const tool of tools) {
      toolItems.push({
        alertStock: tool.alertStock,
        attachments: tool.attachments,
        createdAt: tool.createdAt,
        description: tool.description,
        name: tool.name,
        stock: Infinity,
        uid: tool.uid,
        updatedAt: tool.updatedAt,
        value: tool.value,
        deleted: tool.deleted,
      })
    }

    return toolItems
  }

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '50%', type: 'text' },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '30%',
        type: 'number',
        inputProps: {
          min: 1,
          maxKey: 'stock',
          defaultValue: (item) => item.quantity ?? 1,
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
    ] as GenericColumnType<CommitToolBaseType['tools'][0]>[]
  }

  function handleMaterialInput(
    key: keyof CommitToolBaseType['tools'][0],
    value: number | string | boolean,
    uid: string,
  ) {
    const toolIdx = tools.findIndex((item) => item.uid === uid)

    if (toolIdx === -1) return

    formCommitTool.setFieldValue(`tools.${toolIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }

  function onClose() {
    setConfirmExit(false)
    outerOnClose?.()
  }
}

export default CommitToolForm
