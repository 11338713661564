import React from 'react'
import { useListState } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import { IconCubePlus, IconEdit, IconTrash } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ActionIcon, Badge, Button, Container, Group, List, Text } from '@mantine/core'

import { ModalFormType } from '@customTypes/modal'
import { ClientBaseType, GetClientsType } from '@customTypes/client'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'
import { PriorityClient } from '@constants/client'

import { ErrorService } from '@utils/error'
import { isLoadingMutation } from '@utils/network'
import { onFilterClient } from '@utils/client'

import { useParams } from '@hooks/params'

import ClientForm from '@components/client/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { RoutesApp } from '@constants/routes'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

const Client = () => {
  const [modalForm, setModalForm] = React.useState<ModalFormType<ClientBaseType>>({
    opened: false,
  })

  const navigate = useNavigate()

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalDelete, setModalDelete] = React.useState<ClientBaseType[] | null>(null)

  const [params, setParams] = useParams<GetClientsType>({
    queryKey: QueryKeys.GET_CLIENTS_KEY,
    initialParams: {},
  })

  const [selectedRows, handlers] = useListState<string>([])

  const clientsQuery = useQuery<ClientBaseType[], ErrorService, ClientBaseType[]>({
    queryKey: [QueryKeys.GET_CLIENTS_KEY],
    queryFn: async () => {
      const response = await DataRepo.clientEntityService.getClients({})

      return response
    },
  })

  const clientsDeleteMutation = useMutation<void, ErrorService, string[]>({
    mutationFn: async (uids) => {
      const response = await DataRepo.clientEntityService.deleteClients(uids)

      setParams({})

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_CLIENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar el cliente',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Eliminación exitosa',
      })

      setModalDelete(null)
      handlers.setState([])
    },
  })

  const memoFilterClient = React.useMemo(
    () =>
      onFilterClient({
        data: clientsQuery.data,
        params,
      }),
    [clientsQuery.data, params],
  )

  React.useEffect(() => {
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = isLoadingMutation(clientsQuery)

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Clientes</Text>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() => setModalForm({ opened: true })}
          >
            Agregar
          </Button>
        </div>

        <TableCustom<ClientBaseType>
          columns={buildColumns()}
          data={memoFilterClient}
          keyId="uid"
          loading={isLoading}
          loadingMessage="Cargando clientes"
          minWidth={minWidth}
          placeholderSearch="Buscar cliente"
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            const data = clientsQuery.data?.filter((item) => ids.includes(item.uid))

            if (!data?.length) return

            setModalDelete(data)
          }}
          onDetail={(item) => {
            navigate(RoutesApp.CLIENT.replace(':uid', item.uid))
          }}
          onSearch={(query, field) => setParams({ query, field })}
        />
      </div>

      <ClientForm
        modal
        data={modalForm.data}
        open={modalForm.opened}
        size="xl"
        onClose={() => setModalForm({ opened: false })}
        onSubmitted={() => {
          setParams({})
        }}
      />

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={isLoadingMutation(clientsDeleteMutation)}
        opened={Boolean(modalDelete?.length)}
        title="Eliminar cliente"
        onCancel={() => setModalDelete(null)}
        onConfirm={() => {
          if (!modalDelete) return

          clientsDeleteMutation.mutate(modalDelete.map((item) => item.uid))
        }}
      >
        {modalDelete?.length === 1 && (
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la cliente{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalDelete[0].name}
            </Text>
            ?
          </Text>
        )}
        {modalDelete && modalDelete?.length > 1 && (
          <React.Fragment>
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar los siguientes clientes?
            </Text>
            <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
              {modalDelete.map((item) => (
                <List.Item key={item.uid}>
                  <Text className="cd-text-base">{item.name}</Text>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        )}
      </ConfirmationModal>
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'ruc', label: 'RUC', width: '15%', type: 'text', searchable: true },
      { key: 'name', label: 'Nombre', width: '30%', type: 'text', searchable: true },
      { key: 'email', label: 'Email', width: '30%', type: 'text', searchable: true },
      {
        key: 'priority',
        label: 'Prioridad',
        width: '10%',
        type: 'calc',
        searchable: true,
        defaultOnClick: true,
        render: ({ priority }) => (
          <div className="cd-text-center">
            <Badge color={PriorityClient[priority].color}>{PriorityClient[priority].label}</Badge>
          </div>
        ),
      },
      {
        key: 'actions',
        label: 'Acciones',
        width: '15%',
        type: 'calc',
        align: 'center',
        render: (item) => (
          <Group justify="center">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                setModalForm({ opened: true, data: item })
              }}
            >
              <IconEdit size={18} />
            </ActionIcon>
            <ActionIcon
              color="red"
              variant="transparent"
              onClick={() => {
                setModalDelete([item])
              }}
            >
              <IconTrash size={18} />
            </ActionIcon>
          </Group>
        ),
      },
    ] as GenericColumnType<ClientBaseType>[]
  }
}

export default Client
