import React from 'react'
import moment from 'moment'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IconCubePlus, IconEdit, IconPaperclip, IconTrash } from '@tabler/icons-react'
import { ActionIcon, Box, Button, Group, List, Modal, Text } from '@mantine/core'

import { CommitBaseType } from '@customTypes/commit'
import { ModalFormType, ModalProps } from '@customTypes/modal'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import DataRepo from '@api/datasource/data'

import { ErrorService } from '@utils/error'
import { invalidateDataQuery, isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import QueryKeys from '@constants/queryKeys'
import { DATE_FORMAT, UI } from '@constants/app'
import { CommitAction } from '@constants/commits'

import TableCustom, { GenericColumnType } from '@components/shared/table'
import CommitBasePreview, { CommitBasePreviewType } from '@components/commits/previewBase'
import { useListState } from '@mantine/hooks'
import ConfirmationModal from '@components/shared/confirmationModal'
import { notifications } from '@mantine/notifications'

export type CommitRawMaterialType = {
  warehouseID: string
  warehouseName: string
}

type CommitsWarehouseProps = {
  onAddCommit: () => void
  onEditCommit: (commit: CommitBaseType) => void
}

const CommitsWarehouse = (props: ModalProps<CommitRawMaterialType> & CommitsWarehouseProps) => {
  const {
    open,
    modal,
    data = {} as CommitRawMaterialType,
    size = 'lg',
    onClose: outerOnClose,
    onAddCommit,
    onEditCommit,
  } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [selected, handlers] = useListState<string>([])

  const [modalDelete, setModalDelete] = React.useState<CommitBaseType[] | null>(null)

  const [modalForm, setModalForm] = React.useState<ModalFormType<CommitBasePreviewType>>({
    opened: false,
  })

  const { warehouseID, warehouseName } = data

  const commitsQuery = useQuery<CommitBaseType[], ErrorService, CommitBaseType[], string[]>({
    initialData: [],
    refetchOnMount: true,
    enabled: Boolean(warehouseID),
    queryKey: [QueryKeys.GET_COMMITS_KEY, String(warehouseID)],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.warehouseEntitiesService.getCommits({
        warehouseID: queryKey[1],
      })

      return response
    },
  })

  const commitDeleteMutation = useMutation<void, ErrorService, string[]>({
    mutationFn: async (commitIDs) => {
      await DataRepo.warehouseEntitiesService.deleteCommits(commitIDs)

      await invalidateDataQuery({
        queryKeys: [QueryKeys.GET_COMMITS_KEY, warehouseID],
        exact: true,
      })

      await invalidateDataQuery({
        queryKeys: [QueryKeys.GET_WAREHOUSE_KEY, warehouseID],
        exact: true,
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Ocurrió un error al eliminar el registro',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Registro eliminado',
        message: 'El registro se eliminó correctamente',
      })

      setModalDelete(null)
    },
  })

  React.useEffect(() => {
    if (!open) {
      handlers.setState([])
    }
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const content = warehouseID && (
    <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
      <div className="cd-flex cd-grow cd-justify-end">
        <Button
          fullWidth
          className="md:cd-basis-[20%]"
          color="green"
          leftSection={<IconCubePlus size={18} />}
          size={UI.Size}
          variant="filled"
          onClick={onAddCommit}
        >
          Modificar stock
        </Button>
      </div>

      <TableCustom<CommitBaseType>
        columns={buildColumns()}
        data={commitsQuery.data}
        keyId="uid"
        limitPage={5}
        loading={isLoadingOrRefetchQuery(commitsQuery)}
        loadingMessage="Cargando registros"
        minWidth={minWidth}
        noDataMessage="No hay registros"
        selectedRows={selected}
        onChangeSelected={handlers}
        onDelete={(ids) => {
          if (!commitsQuery.data) return

          const filtered = commitsQuery.data.filter((item) => ids.includes(item.uid))
          setModalDelete(filtered)
        }}
        onDetail={(item) => {
          setModalForm({
            opened: true,
            data: {
              commit: item,
              warehouseName,
            },
          })
          //onEditCommit(item)
        }}
      />

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={isLoadingMutation(commitDeleteMutation)}
        opened={Boolean(modalDelete?.length)}
        title="Eliminar materia prima de bodega"
        onCancel={() => setModalDelete(null)}
        onConfirm={() => {
          if (!modalDelete || !commitsQuery.data) return

          commitDeleteMutation.mutate(modalDelete.map((item) => item.uid))
        }}
      >
        {modalDelete?.length === 1 && (
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar el registro{' '}
            <Text inherit className="cd-text-base" component="span">
              <strong>{CommitAction[modalDelete[0].action].label}</strong> creaddo por{' '}
              {modalDelete[0].user?.displayName ?? modalDelete[0].user?.email}
            </Text>
            ?
          </Text>
        )}
        {modalDelete && modalDelete?.length > 1 && (
          <React.Fragment>
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar las siguientes materias primas?
            </Text>
            <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
              {modalDelete.map((item) => (
                <List.Item key={item.uid}>
                  <Text className="cd-text-base">
                    <strong>{CommitAction[item.action].label}</strong> creado por{' '}
                    {item.user?.displayName ?? item.user?.email}
                  </Text>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        )}
      </ConfirmationModal>

      <CommitBasePreview
        modal
        data={modalForm.data}
        open={modalForm.opened}
        size="xl"
        onClose={() => setModalForm({ opened: false })}
      />
    </div>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={`Historial de registros de ${warehouseName}`}
        onClose={() => onClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      { key: 'message', label: 'Mensaje', width: '30%', type: 'text' },
      {
        key: 'action',
        label: 'Acción',
        width: '10%',
        type: 'calc',
        defaultOnClick: true,
        render: (item) => CommitAction[item.action].label,
      },
      {
        key: 'attachment',
        label: 'Adjunto',
        width: '7%',
        type: 'calc',
        defaultOnClick: true,
        render: (item) =>
          item.attachment ? (
            <div className="cd-flex cd-justify-center">
              <IconPaperclip size={18} />
            </div>
          ) : (
            <Box c="dimmed">Sin adjunto</Box>
          ),
      },
      {
        key: 'user',
        label: 'Usuario',
        width: '20%',
        type: 'calc',
        defaultOnClick: true,
        render: (item) => item.user?.displayName || item.user?.email,
      },
      {
        key: 'updatedAt',
        label: 'Fecha',
        width: '10%',
        type: 'calc',
        defaultOnClick: true,
        render: (item) => moment.unix(item.updatedAt).format(DATE_FORMAT),
      },
      {
        key: 'actions',
        label: 'Acciones',
        width: '10%',
        type: 'calc',
        align: 'center',
        render: (item) => (
          <Group justify="center">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                onEditCommit(item)
              }}
            >
              <IconEdit size={18} />
            </ActionIcon>
            <ActionIcon
              color="red"
              variant="transparent"
              onClick={() => {
                setModalDelete([item])
              }}
            >
              <IconTrash size={18} />
            </ActionIcon>
          </Group>
        ),
      },
    ] as GenericColumnType<CommitBaseType>[]
  }

  function onClose() {
    outerOnClose?.()
  }
}

export default CommitsWarehouse
