import React from 'react'
import { useIsMobile } from '@hooks/mobile'
import { Button, FileInput, Modal, Text } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'

import DataRepo from '@api/datasource/data'

import { ModalProps } from '@customTypes/modal'
import { CreateGeoCheckpointSchema, CreateGeoCheckpointType } from '@customTypes/geoCheckpoint'

import { ErrorService } from '@utils/error'
import { fileImageToBase64 } from '@utils/image'
import { invalidateDataQuery, isLoadingMutation } from '@utils/network'

import Input from '@components/shared/input'
import ConfirmationModal from '@components/shared/confirmationModal'
import QueryKeys from '@constants/queryKeys'

const INITIAL: CreateGeoCheckpointType = {
  attachment: '',
  message: '',
}

const GeoCheckpointForm = (props: ModalProps) => {
  const { open, modal, size = 'lg', onClose: outerOnClose } = props

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [fileName, setFileName] = React.useState<string>('')

  const isMobile = useIsMobile()

  const formGeoCheckpoint = useForm<CreateGeoCheckpointType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateGeoCheckpointSchema),
  })

  const geoCheckpointMutation = useMutation<void, ErrorService, CreateGeoCheckpointType>({
    mutationFn: async (data) => {
      await DataRepo.userService.saveUserGeoCheckpoint(data)

      invalidateDataQuery({
        queryKeys: [QueryKeys.GET_GEO_CHECKPOINTS_KEY],
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Error al guardar el registro',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Guardado',
        message: 'Registro guardado con éxito',
      })

      outerOnClose?.()
    },
  })

  React.useEffect(() => {
    if (!open) {
      formGeoCheckpoint.setValues(INITIAL)
    }
    formGeoCheckpoint.resetDirty()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const isLoading = isLoadingMutation(geoCheckpointMutation)

  const attatchmentProps = formGeoCheckpoint.getInputProps('attachment')

  const content = (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formGeoCheckpoint.onSubmit((data) => {
        geoCheckpointMutation.mutate(data)
      })}
    >
      <Input
        {...formGeoCheckpoint.getInputProps('message')}
        autosize
        label="Mensaje"
        maxRows={4}
        minRows={2}
        placeholder="Descripción el motivo de la visita"
        typeInput="textarea"
      />

      <FileInput
        {...formGeoCheckpoint.getInputProps('attachment')}
        clearable
        capture="user"
        label="Evidencia"
        placeholder="Capture una imágen de la visita"
        value={undefined}
        valueComponent={(f) => (f ? fileName : 'Capture una imágen de la visita')}
        onChange={(f) =>
          fileImageToBase64(f)
            .then((image) => {
              attatchmentProps.onChange(image)
              setFileName(f?.name || '')
            })
            .catch(() =>
              notifications.show({
                color: 'red',
                title: 'Error',
                message: 'Error al cargar la imagen',
              }),
            )
        }
      />

      <Button loaderProps={{ type: 'dots' }} loading={isLoading} type="submit">
        Guardar
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title="Nuevo GeoCheckpoint"
        onClose={() => {
          if (formGeoCheckpoint.isDirty()) {
            setConfirmExit(true)
          } else {
            outerOnClose?.()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function onClose() {
    setConfirmExit(false)
    outerOnClose?.()
  }
}

export default GeoCheckpointForm
