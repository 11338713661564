import { Helmet } from 'react-helmet'
import { useMantineColorScheme } from '@mantine/core'
import { QueryClientProvider } from '@tanstack/react-query'
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import queryClient from '@api/datasource/query'

import { RoutesApp } from '@constants/routes'

//Public
import Root from '@pages/public/root'
import Login from '@pages/auth/login'
import Register from '@pages/auth/register'

//Private
import Home from '@pages/private/home'

import Export from '@pages/private/export'

import RawMaterial from '@pages/private/rawMaterial'
import Warehouse from '@pages/private/warehouse'

import Tool from '@pages/private/tool'
import WarehouseTool from '@pages/private/warehouseTool'

import Receipts from '@pages/private/receipts'

import Block from '@pages/private/block'

import GeoCheckpoint from '@pages/private/geoCheckpoint'

import Client from '@pages/private/client'
import ClientPreview from '@components/client/preview'

import Proformas from '@pages/private/proforma'
import ProformasForm from '@components/proforma/form'
import ProformasPreview from '@components/proforma/preview'

import Drawer from '@components/drawer'

const App = () => {
  const { colorScheme } = useMantineColorScheme()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Helmet>
          <meta content={colorScheme === 'dark' ? '#242424' : '#ffffff'} name="theme-color" />
        </Helmet>
        <Routes>
          <Route element={<Login />} path={RoutesApp.LOGIN} />
          <Route element={<Register />} path={RoutesApp.REGISTER} />
          <Route element={<Drawer />}>
            <Route element={<Home />} path={RoutesApp.HOME} />
            <Route element={<Export />} path={RoutesApp.EXPORT} />
            <Route element={<Tool />} path={RoutesApp.TOOLS} />
            <Route element={<WarehouseTool />} path={RoutesApp.WAREHOUSE_TOOLS} />
            <Route element={<Receipts />} path={RoutesApp.RECEIPTS} />
            <Route element={<Warehouse />} path={RoutesApp.WAREHOUSE} />
            <Route element={<RawMaterial />} path={RoutesApp.RAW_MATERIALS} />
            <Route element={<Block />} path={RoutesApp.BLOCK} />
            <Route element={<Client />} path={RoutesApp.CLIENTS} />
            <Route element={<ClientPreview />} path={RoutesApp.CLIENT} />
            <Route element={<GeoCheckpoint />} path={RoutesApp.GEO_CHECKPOINTS} />
            <Route element={<Proformas />} path={RoutesApp.PROFORMAS} />
            <Route element={<ProformasForm />} path={RoutesApp.PROFORMA_FORM} />
            <Route element={<ProformasPreview />} path={RoutesApp.PROFORMA_VIEW} />
          </Route>
          <Route element={<Root />} path="/" />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export { App }
