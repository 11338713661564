import React from 'react'
import { useListState } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { Button, Loader, Modal, Text } from '@mantine/core'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  CommitBaseType,
  CreateCommitType,
  UpdateCommitType,
  CreateCommitSchema,
} from '@customTypes/commit'
import { ModalProps } from '@customTypes/modal'
import { RawMaterialBaseType } from '@customTypes/rawMaterial'

import QueryKeys from '@constants/queryKeys'
import { CommitAction } from '@constants/commits'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import { Dropzone } from '@components/shared/dropzone'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { WarehouseType } from '@customTypes/warehouse'
import { ellipsis } from '@utils/string'

type RMItem = WarehouseType['rawMaterials'][0] & { stock: number }

export type CommitFormType = {
  warehouseID: string
  warehouseName: string
  rawMaterialsWarehouse: RMItem[]
  commit?: CommitBaseType
}

const INITIAL: CreateCommitType['commit'] = {
  message: '',
  action: 'consume',
  attachment: null,
  rawMaterials: [],
}

const CommitForm = (props: ModalProps<CommitFormType>) => {
  const { open, modal, data = {} as CommitFormType, size = 'lg', onClose: outerOnClose } = props

  const [confirmExit, setConfirmExit] = React.useState(false)

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [selectedRows, handlers] = useListState<string>([])

  const formCommit = useForm<CreateCommitType['commit']>({
    initialValues: INITIAL,
    validate: zodResolver(CreateCommitSchema.shape.commit),
  })

  const commitCreateMutation = useMutation<CommitBaseType, ErrorService, CreateCommitType>({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.createCommit(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_COMMITS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_KEY, warehouseID],
        refetchType: 'all',
        exact: true,
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear el registro',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Registro creado correctamente',
      })

      onClose?.()
    },
  })

  const commitUpdateMutation = useMutation<void, ErrorService, UpdateCommitType>({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.updateCommit(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_COMMITS_KEY, data.warehouseID],
        refetchType: 'all',
        exact: true,
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar el registro',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Registro actualizado correctamente',
      })

      onClose?.()
    },
  })

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService>({
    initialData: [],
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      return await DataRepo.constructionEntitiesService.getRawMaterials({
        orderDir: 'asc',
      })
    },
  })

  React.useEffect(() => {
    if (open && data?.commit) {
      formCommit.setValues(data.commit)
    } else if (!open) {
      formCommit.setValues(INITIAL)
      handlers.setState([])
    }
    formCommit.resetDirty()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.commit, open])

  const isLoading = isLoadingMutation(commitCreateMutation, commitUpdateMutation)

  const { rawMaterials, action } = formCommit.getValues()

  const { warehouseID, rawMaterialsWarehouse } = data

  const sourceRawMaterials =
    action === 'refill' ? parseRawMaterials(rawMaterialsQuery.data) : rawMaterialsWarehouse

  const content = warehouseID && (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formCommit.onSubmit((values) => {
        if (!warehouseID) return

        if (data.commit) {
          commitUpdateMutation.mutate({
            warehouseID,
            current: values,
            previous: data.commit,
          })
        } else {
          commitCreateMutation.mutate({
            warehouseID,
            commit: values,
          })
        }
      })}
    >
      <Input
        data={Object.values([CommitAction.consume, CommitAction.refill])}
        label="Tipo de acción"
        typeInput="segmentedControl"
        {...formCommit.getInputProps('action')}
        onBlur={() => formCommit.validateField('action')}
        onChange={(value) => {
          formCommit.setFieldValue('action', value as CommitBaseType['action'], {
            forceUpdate: true,
          })
          formCommit.setFieldValue('rawMaterials', [], { forceUpdate: true })
        }}
      />

      <Input
        autosize
        label="Mensaje"
        maxRows={4}
        minRows={2}
        placeholder="Describa la acción realizada"
        typeInput="textarea"
        {...formCommit.getInputProps('message')}
        onBlur={() => formCommit.validateField('message')}
      />

      <Dropzone
        files={formCommit.values.attachment ? [formCommit.values.attachment] : []}
        label="Adjuntar archivo"
        maxFiles={1}
        multiple={false}
        onDrop={(files) => formCommit.setFieldValue('attachment', files[0])}
      />

      <Input
        clearable
        searchable
        data={buildSelectOptions<RMItem>({
          data: sourceRawMaterials.filter(
            (item) => !rawMaterials.some((rm) => rm.uid === item.uid),
          ),
          value: 'uid',
          label: (item) => `${item.name} (${ellipsis(item.description)})`,
        })}
        filter={(input) =>
          filterSelect({
            limit: 5,
            search: input.search,
            options: input.options,
          })
        }
        nothingFoundMessage={
          <div>
            <Text c="dimmed" className="cd-text-sm">
              {rawMaterialsQuery.data.length
                ? 'No se pueden agregar materias primas existentes'
                : 'No hay materias primas'}
            </Text>
          </div>
        }
        placeholder="Escribe para buscar materia prima existente"
        rightSection={
          isLoadingOrRefetchQuery(rawMaterialsQuery) ? (
            <Loader size={18} />
          ) : (
            <IconSearch size={18} />
          )
        }
        typeInput="select"
        value={undefined}
        withCheckIcon={false}
        onChange={(value) => {
          const rawMaterial = sourceRawMaterials.find((item) => item.uid === value)

          if (!rawMaterial) return

          formCommit.insertListItem('rawMaterials', {
            ...rawMaterial,
            quantity: 1,
          })
        }}
      />

      <TableCustom<CommitBaseType['rawMaterials'][0]>
        columns={buildColumns()}
        data={rawMaterials}
        keyId="uid"
        minWidth={minWidth}
        noDataMessage="No se han agregado materias primas"
        selectedRows={selectedRows}
        onChangeSelected={handlers}
        onDelete={(ids) => {
          const filtered = rawMaterials.filter((item) => !ids.includes(item.uid))

          formCommit.setFieldValue('rawMaterials', filtered, { forceUpdate: true })
          handlers.setState([])
        }}
      />

      <Button loaderProps={{ type: 'dots' }} loading={isLoading} type="submit">
        {data.commit ? 'Editar' : 'Crear'} registro
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={`${data.commit ? 'Editar' : 'Nuevo'} registro de bodega ${data.warehouseName}`}
        onClose={() => {
          if (formCommit.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function parseRawMaterials(rawMaterials: RawMaterialBaseType[]) {
    const rawMaterialItems: RMItem[] = []

    for (const rawMaterial of rawMaterials) {
      rawMaterialItems.push({
        alertStock: rawMaterial.alertStock,
        attachments: rawMaterial.attachments,
        createdAt: rawMaterial.createdAt,
        description: rawMaterial.description,
        measure: rawMaterial.measure,
        name: rawMaterial.name,
        stock: Infinity,
        uid: rawMaterial.uid,
        updatedAt: rawMaterial.updatedAt,
        value: rawMaterial.value,
        deleted: rawMaterial.deleted,
      })
    }

    return rawMaterialItems
  }

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '50%', type: 'text' },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '30%',
        type: 'number',
        inputProps: {
          min: 1,
          maxKey: 'stock',
          defaultValue: (item) => item.quantity ?? 1,
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
    ] as GenericColumnType<CommitBaseType['rawMaterials'][0]>[]
  }

  function handleMaterialInput(
    key: keyof CommitBaseType['rawMaterials'][0],
    value: number | string | boolean,
    uid: string,
  ) {
    const rawMaterialIdx = rawMaterials.findIndex((item) => item.uid === uid)

    if (rawMaterialIdx === -1) return

    formCommit.setFieldValue(`rawMaterials.${rawMaterialIdx}.${key}`, value, { forceUpdate: true })
  }

  function onClose() {
    setConfirmExit(false)
    outerOnClose?.()
  }
}

export default CommitForm
