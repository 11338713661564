/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { useForm } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { Button, Modal, Text } from '@mantine/core'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  WarehouseBaseType,
  CreateWarehouseType,
  UpdateWarehouseType,
  CreateWarehouseSchema,
} from '@customTypes/warehouse'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'
import { ModalFormType, ModalProps } from '@customTypes/modal'
import { RawMaterialBaseType } from '@customTypes/rawMaterial'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import RawMaterialForm from '@components/rawMaterial/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import NumberFormat from '@components/shared/Number'

const INITIAL: CreateWarehouseType = {
  name: '',
  description: '',
  rawMaterials: [],
  inHouse: false,
}

const WarehouseForm = (props: ModalProps<WarehouseBaseType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [selectedRows, handlersSelected] = useListState<string>([])

  const [modalForm, setModalForm] = React.useState<ModalFormType<RawMaterialBaseType>>({
    opened: false,
  })

  const formWarehouse = useForm<CreateWarehouseType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateWarehouseSchema),
  })

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService, RawMaterialBaseType[]>({
    initialData: [],
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getRawMaterials({})

      return response
    },
  })

  const materialCreateMutation = useMutation<WarehouseBaseType, ErrorService, CreateWarehouseType>({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.createWarehouse(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_WAREHOUSES_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.(response)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Bodega creada correctamente',
      })

      onClose()
    },
  })

  const warehouseUpdateMutation = useMutation<void, ErrorService, UpdateWarehouseType>({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.updateWarehouse(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_KEY, data.uid],
        refetchType: 'all',
        exact: true,
      })

      onSubmitted?.()

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Bodega actualizada correctamente',
      })

      onClose?.()
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formWarehouse.setValues(data)
    } else if (!open) {
      formWarehouse.setValues(INITIAL)
      handlersSelected.setState([])
    }
    formWarehouse.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data])

  const { rawMaterials } = formWarehouse.getValues()

  const isLoading = isLoadingMutation(materialCreateMutation, warehouseUpdateMutation)

  const content = (
    <form
      onSubmit={formWarehouse.onSubmit((values) => {
        if (data) {
          warehouseUpdateMutation.mutate({
            ...values,
            uid: data.uid,
          } as UpdateWarehouseType)
        } else {
          materialCreateMutation.mutate(values as CreateWarehouseType)
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Input
          label="Nombre"
          placeholder="Nombre"
          typeInput="text"
          {...formWarehouse.getInputProps('name')}
          onBlur={() => formWarehouse.validateField('name')}
        />
        <Input
          autosize
          label="Descripción"
          maxRows={4}
          minRows={2}
          placeholder="Escriba una descripción de la bodega o proyecto"
          typeInput="textarea"
          {...formWarehouse.getInputProps('description')}
          onBlur={() => formWarehouse.validateField('description')}
        />

        <Input
          label="¿Es una bodega interna?"
          labelPosition="left"
          size="lg"
          typeInput="switch"
          {...formWarehouse.getInputProps('inHouse')}
        />

        {data && (
          <Text c="dimmed">
            Para cambiar el stock de una materia prima, haga clic en el botón "Ver registros" →
            "Modificar Stock". Cierre el formulario para ver el botón
          </Text>
        )}

        {!data && (
          <React.Fragment>
            <Input
              allowDeselect
              clearable
              searchable
              className="cd-mt-[1rem]"
              data={buildSelectOptions<RawMaterialBaseType>({
                data: rawMaterialsQuery.data.filter(
                  (item) => !rawMaterials.some((rm) => rm.uid === item.uid),
                ),
                value: 'uid',
                label: 'name',
              })}
              filter={(input) =>
                filterSelect({
                  ...input,
                  limit: 5,
                })
              }
              loading={isLoadingOrRefetchQuery(rawMaterialsQuery)}
              nothingFoundMessage={
                <div>
                  <Text c="dimmed" className="cd-text-sm">
                    {rawMaterialsQuery.data?.length
                      ? 'No se pueden duplicar materias primas, para cambiar sotck de clic en el icono de editar en la tabla anterior'
                      : 'No hay materias primas disponibles'}
                  </Text>
                  <Button
                    className="cd-mt-[1rem]"
                    color="blue"
                    size="xs"
                    variant="light"
                    onClick={() => setModalForm({ opened: true })}
                  >
                    Crear nueva materia prima
                  </Button>
                </div>
              }
              placeholder="Escribe para buscar materia prima existente"
              rightSection={<IconSearch size={18} />}
              typeInput="select"
              value={null}
              onChange={(value) => {
                const rawMaterial = rawMaterialsQuery.data?.find((item) => item.uid === value)

                if (!rawMaterial) return

                formWarehouse.insertListItem('rawMaterials', {
                  ...rawMaterial,
                  stock: 1,
                } as CreateWarehouseType['rawMaterials'][0])
              }}
            />

            <TableCustom<CreateWarehouseType['rawMaterials'][0]>
              columns={buildColumns()}
              data={rawMaterials}
              keyId="uid"
              limitPage={5}
              minWidth={minWidth}
              noDataMessage="No se han agregado materias primas"
              placeholderSearch="Buscar materia prima"
              selectedRows={selectedRows}
              onChangeSelected={handlersSelected}
              onDelete={(ids) => {
                const filtered = rawMaterials.filter((item) => !ids.includes(item.uid))

                formWarehouse.setFieldValue('rawMaterials', filtered, { forceUpdate: true })
                handlersSelected.setState([])
              }}
            />
          </React.Fragment>
        )}

        <RawMaterialForm
          modal
          data={modalForm.data}
          open={modalForm.opened}
          size="xl"
          onClose={() => setModalForm({ opened: false })}
          onSubmitted={(rm) => {
            if (!rm) return

            formWarehouse.insertListItem('rawMaterials', rm)
          }}
        />

        <Button
          className="cd-mt-[1rem]"
          disabled={!formWarehouse.isValid()}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data ? 'Guardar bodega' : 'Agregar bodega'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data ? 'Editar bodega' : 'Agregar bodega'}
        onClose={() => {
          if (formWarehouse.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '3%', type: 'text' },
      { key: 'measure', label: 'Medida', width: '1%', type: 'text' },
      {
        key: 'quantity',
        label: 'Stock',
        width: '2%',
        type: 'number',
        inputProps: {
          min: 1,
          defaultValue: 1,
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'stock'),
        },
      },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '3%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
    ] as GenericColumnType<CreateWarehouseType['rawMaterials'][0]>[]
  }

  function handleMaterialInput(
    key: keyof CreateWarehouseType['rawMaterials'][0],
    value: number | string,
    uid: string,
  ) {
    const rawMaterialIdx = rawMaterials.findIndex((item) => item.uid === uid)

    if (rawMaterialIdx === -1) return

    formWarehouse.setFieldValue(`rawMaterials.${rawMaterialIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }

  function onClose() {
    outerOnClose?.()
    setConfirmExit(false)
    formWarehouse.reset()
  }
}

export default WarehouseForm
