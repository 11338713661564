import React from 'react'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { IconCubePlus, IconEdit } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ActionIcon, Button, Container, Group, List, Text } from '@mantine/core'

import { ModalFormType } from '@customTypes/modal'
import { RawMaterialBaseType, GetRawMaterialsType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { ErrorService } from '@utils/error'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'
import { onFilterRawMaterial } from '@utils/rawMaterial'

import { useParams } from '@hooks/params'

import RawMaterialForm from '@components/rawMaterial/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import RawMaterialPreview from '@components/rawMaterial/preview'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'
import NumberFormat from '@components/shared/Number'

const RawMaterial = () => {
  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalForm, setModalForm] = React.useState<ModalFormType<RawMaterialBaseType>>({
    opened: false,
  })

  const [modalPreview, setModalPreview] = React.useState<ModalFormType<RawMaterialBaseType>>({
    opened: false,
  })

  const [modalDelete, setModalDelete] = React.useState<RawMaterialBaseType[] | null>(null)

  const [params, setParams] = useParams<GetRawMaterialsType>({
    queryKey: QueryKeys.GET_MATERIALS_KEY,
    initialParams: {},
  })

  const [selectedRows, handlers] = useListState<string>([])

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService, RawMaterialBaseType[]>({
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getRawMaterials({})

      return response
    },
  })

  const materialsDeleteMutation = useMutation<void, ErrorService, string[]>({
    mutationFn: async (uids) => {
      const response = await DataRepo.constructionEntitiesService.deleteRawMaterials({
        rawMaterialIDs: uids,
      })

      setParams({})

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_MATERIALS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar la materia prima',
        })
      }

      setModalDelete(null)
      handlers.setState([])
    },
  })

  const memoFilterRawMaterial = React.useMemo(
    () =>
      onFilterRawMaterial({
        data: rawMaterialsQuery.data,
        params,
      }),
    [rawMaterialsQuery.data, params],
  )

  React.useEffect(() => {
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = isLoadingOrRefetchQuery(rawMaterialsQuery)

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Materias Primas</Text>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() => setModalForm({ opened: true })}
          >
            Agregar
          </Button>
        </div>

        <TableCustom<RawMaterialBaseType>
          columns={buildColumns()}
          data={memoFilterRawMaterial}
          keyId="uid"
          loading={isLoading}
          loadingMessage="Cargando materias primas"
          minWidth={minWidth}
          placeholderSearch="Buscar materia prima"
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            const data = rawMaterialsQuery.data?.filter((item) => ids.includes(item.uid))

            if (!data?.length) return

            setModalDelete(data)
          }}
          onDetail={(item) => {
            setModalPreview({ opened: true, data: item })
          }}
          onSearch={(query, field) => setParams({ query, field })}
        />
      </div>

      <RawMaterialForm
        modal
        data={modalForm.data}
        open={modalForm.opened}
        onClose={() => setModalForm({ opened: false })}
        onSubmitted={() => {
          setParams({})
        }}
      />

      <RawMaterialPreview
        modal
        data={modalPreview.data}
        open={modalPreview.opened}
        onClose={() => setModalPreview({ opened: false })}
      />

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={isLoadingMutation(materialsDeleteMutation)}
        opened={Boolean(modalDelete?.length)}
        title="Eliminar materia prima"
        onCancel={() => setModalDelete(null)}
        onConfirm={() => {
          if (!modalDelete) return

          materialsDeleteMutation.mutate(modalDelete.map((item) => item.uid))
        }}
      >
        {modalDelete?.length === 1 && (
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la materia prima{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalDelete[0].uid} - {modalDelete[0].name}
            </Text>
            ?
          </Text>
        )}
        {modalDelete && modalDelete?.length > 1 && (
          <React.Fragment>
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar las siguientes materias primas?
            </Text>
            <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
              {modalDelete.map((item) => (
                <List.Item key={item.uid}>
                  <Text className="cd-text-base">
                    {item.uid} - {item.name}
                  </Text>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        )}
      </ConfirmationModal>
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '10%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '25%', type: 'text', searchable: true },
      { key: 'measure', label: 'Medida', width: '3%', type: 'text', searchable: true },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '7%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
      { key: 'alertStock', label: 'Alerta', width: '5%', type: 'text', align: 'right' },
      {
        key: 'actions',
        label: 'Acciones',
        width: '5%',
        type: 'calc',
        align: 'center',
        render: (item) => (
          <Group justify="center">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                setModalForm({ opened: true, data: item })
              }}
            >
              <IconEdit size={18} />
            </ActionIcon>
          </Group>
        ),
      },
    ] as GenericColumnType<RawMaterialBaseType>[]
  }
}

export default RawMaterial
