import React from 'react'
import { useForm } from '@mantine/form'
import { IconArrowRight } from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Fieldset, Modal, Text } from '@mantine/core'

import { MoveToolsType, WarehouseToolBaseType, WarehouseToolType } from '@customTypes/warehouseTool'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions } from '@utils/form'
import { isLoadingMutation } from '@utils/network'

import Input from '@components/shared/input'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { ModalProps } from '@customTypes/modal'

type RMItem = WarehouseToolType['tools'][0] & { maxQuantity: number }

export type MoveToolType = {
  warehouseToolFromID?: string
  tools?: RMItem[]
}

const MoveTools = (props: ModalProps<MoveToolType>) => {
  const { open, modal, data = {}, size = 'lg', onClose, onSubmitted } = props

  const { tools, warehouseToolFromID } = data

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const warehouseToolsQuery = useQuery<
    WarehouseToolBaseType[],
    ErrorService,
    WarehouseToolBaseType[]
  >({
    queryKey: [QueryKeys.GET_WAREHOUSE_TOOLS_KEY],
    queryFn: async () => {
      const response = await DataRepo.warehouseEntitiesService.getWarehouseTools({})

      return response
    },
    initialData: [],
  })

  const formMoveTools = useForm<MoveToolsType>({
    initialValues: {
      warehouseToolFromID: '',
      warehouseToolToID: '',
      tools: [],
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formMoveTools.setValues({
        tools:
          data.tools?.map((tool) => ({
            toolID: tool.uid,
            quantity: 1,
          })) ?? [],
        warehouseToolFromID: warehouseToolFromID ?? '',
        warehouseToolToID: '',
      })
    } else if (!open) {
      formMoveTools.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data.warehouseToolFromID, data.tools])

  const moveToolsMutation = useMutation<void, ErrorService, MoveToolsType>({
    mutationFn: async (data) => {
      await DataRepo.warehouseEntitiesService.moveToolsFromWarehouse(data)

      await queryClient.invalidateQueries({
        predicate: (query) =>
          [QueryKeys.GET_COMMIT_TOOLS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_TOOL_KEY, data.warehouseToolFromID],
        refetchType: 'all',
        exact: true,
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al mover los materiales',
        })
      }

      onClose?.()

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Materiales movidos correctamente',
      })

      onSubmitted?.()
    },
  })

  const content = tools && warehouseToolFromID && (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formMoveTools.onSubmit((values) => moveToolsMutation.mutate(values))}
    >
      <Fieldset legend="Bodegas">
        <div className="cd-flex md:cd-flex-row cd-grow md:cd-gap-x-[1rem]">
          <Input
            readOnly
            className="cd-grow"
            data={buildSelectOptions({
              data: warehouseToolsQuery.data,
              label: 'name',
              value: 'uid',
            })}
            label="Origen"
            placeholder="Seleccione la bodega de origen"
            typeInput="select"
            {...formMoveTools.getInputProps('warehouseToolFromID')}
          />
          <div className="cd-flex cd-flex-col cd-justify-end">
            <IconArrowRight className="cd-mb-[0.5rem]" size={24} />
          </div>
          <Input
            className="cd-grow"
            data={buildSelectOptions({
              data: warehouseToolsQuery.data.filter(
                (warehouseTool) => warehouseTool.uid !== warehouseToolFromID,
              ),
              label: 'name',
              value: 'uid',
            })}
            disabled={warehouseToolsQuery.data.length === 1}
            label="Destino"
            nothingFoundMessage="No hay bodegas disponibles"
            placeholder={
              warehouseToolsQuery.data.length === 1
                ? 'No hay bodegas disponibles'
                : 'Seleccione la bodega de destino'
            }
            typeInput="select"
            {...formMoveTools.getInputProps('warehouseToolToID')}
          />
        </div>
      </Fieldset>

      <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem]">
        <Text className="cd-text-sm">Lista de materiales a mover</Text>
        <TableCustom<RMItem>
          columns={buildColumns()}
          data={tools}
          keyId="uid"
          limitPage={5}
          minWidth={minWidth}
          noDataMessage="No hay materiales disponibles"
        />
      </div>

      <Button
        loaderProps={{ type: 'dots' }}
        loading={isLoadingMutation(moveToolsMutation)}
        type="submit"
      >
        Mover materiales
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title="Mover materiales"
        onClose={() => onClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      {
        key: 'uid',
        label: 'ID',
        type: 'text',
        width: '10%',
      },
      {
        key: 'name',
        label: 'Nombre',
        type: 'text',
        width: '30%',
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        type: 'number',
        width: '15%',
        inputProps: {
          min: 1,
          defaultValue: 1,
          maxKey: 'maxQuantity',
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
    ] as GenericColumnType<RMItem>[]
  }

  function handleMaterialInput(key: string, value: number | string, uid: string) {
    if (!tools) return

    const toolIdx = tools.findIndex((tool) => tool.uid === uid)

    if (toolIdx === -1) return

    formMoveTools.setFieldValue(`tools.${toolIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }
}

export default MoveTools
