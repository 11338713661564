import moment from 'moment'
import { IconDownload } from '@tabler/icons-react'
import { Button, Divider, Image, Modal, Paper, Table, Text } from '@mantine/core'

import { ModalProps } from '@customTypes/modal'
import { CommitBaseType } from '@customTypes/commit'

import { DATE_FORMAT } from '@constants/app'
import { CommitAction } from '@constants/commits'

import NumberFormat from '@components/shared/Number'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { downloadAttachment } from '@utils/firebase'

export type CommitBasePreviewType = {
  commit?: CommitBaseType
  warehouseName: string
}

const CommitBasePreview = (props: ModalProps<CommitBasePreviewType>) => {
  const { open, modal, data = {} as CommitBasePreviewType, size = 'lg', onClose } = props

  const { commit, warehouseName } = data

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const content = commit && (
    <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
      <Divider label="Registro" />

      <Paper withBorder>
        <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
          <Table striped withColumnBorders withRowBorders className="cd-w-full">
            <Table.Thead>
              <Table.Tr>
                <Table.Th w="40%">Mensaje</Table.Th>
                <Table.Th w="15%">Acción</Table.Th>
                <Table.Th w="25%">Usuario</Table.Th>
                <Table.Th w="20%">Fecha</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td w="40%">{commit.message}</Table.Td>
                <Table.Td w="15%">{CommitAction[commit.action].label}</Table.Td>
                <Table.Td w="25%">{commit.user?.displayName || commit.user.email}</Table.Td>
                <Table.Td w="20%">{moment.unix(commit.updatedAt).format(DATE_FORMAT)}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>

      <Divider label="Material primas" />

      <Paper withBorder>
        <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
          <Table striped withColumnBorders withRowBorders className="cd-w-full">
            <Table.Thead>
              <Table.Tr>
                <Table.Th w="50%">Nombre</Table.Th>
                <Table.Th className="cd-text-right" w="20%">
                  Cantidad
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {commit.rawMaterials.map((rawMaterial, index) => (
                <Table.Tr key={index}>
                  <Table.Td w="50%">{rawMaterial.name}</Table.Td>
                  <Table.Td className="cd-text-right" w="20%">
                    <NumberFormat decimals={0} prefix="" value={rawMaterial.quantity} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>

      {commit.attachment && (
        <div>
          <Text className="cd-text-base cd-mb-[0.5rem]">Adjunto</Text>

          <div className="cd-flex cd-flex-col cd-justify-center cd-relative">
            <Image
              alt="Attachment"
              className="cd-flex-none cd-object-contain cd-h-[400px] cd-w-auto"
              radius="sm"
              src={commit.attachment}
              //onClick={() => setImageFull(attachments[0])}
            />
            <div className="cd-flex cd-justify-center cd-mt-[0.5rem]">
              <Button
                color="green"
                leftSection={<IconDownload size={20} />}
                onClick={() => downloadAttachment(commit.attachment!, warehouseName)}
              >
                Descargar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        padding="md"
        size={size}
        title="Detalle de registro"
        onClose={() => onClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content
}

export default CommitBasePreview
