import React from 'react'
import { List, Text, Title, Badge, Button, Divider, Container, ActionIcon } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IconCubePlus, IconHomeMove, IconSearch, IconTrash } from '@tabler/icons-react'

import {
  WarehouseToolType,
  WarehouseToolBaseType,
  GetWarehouseToolsType,
  DeleteToolsFromWarehouseToolType,
} from '@customTypes/warehouseTool'
import { ModalFormType } from '@customTypes/modal'
import { GetToolsType } from '@customTypes/tool'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { useParams } from '@hooks/params'

import { getColorStock } from '@utils/string'
import { buildSelectOptions } from '@utils/form'
import { ErrorCodes, ErrorService } from '@utils/error'
import { onFilterTool } from '@utils/tool'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import WarehouseToolForm from '@components/warehouseTool/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import CommitForm, { CommitToolFormType } from '@components/commitsTool/form'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import MoveTools, { MoveToolType } from '@components/warehouseTool/moveTools'
import CommitsTool, { CommitToolType } from '@components/commitsTool/commitsToolWarehouseTool'
import NumberFormat from '@components/shared/Number'

type QueryKeysType = (string | null)[]

const WarehouseTool = () => {
  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [selectedRows, handlersSelected] = useListState<string>([])

  const [modalDelete, setModalDelete] = React.useState<WarehouseToolType['tools'] | null>(null)

  const [warehouseToolID, setWarehouseToolId] = React.useState<string | null>(null)

  const [modalForm, setModalForm] = React.useState<ModalFormType<WarehouseToolBaseType>>({
    opened: false,
  })

  const [modalCommits, setModalCommits] = React.useState<ModalFormType<CommitToolType>>({
    opened: false,
  })

  const [modalCommitForm, setModalCommitForm] = React.useState<ModalFormType<CommitToolFormType>>({
    opened: false,
  })

  const [modalWarehouseToolDelete, setModalWarehouseToolDelete] = React.useState<
    ModalFormType<WarehouseToolType>
  >({
    opened: false,
  })

  const [modalToolMove, setModalToolMove] = React.useState<ModalFormType<MoveToolType>>({
    opened: false,
  })

  const [params, setParams] = useParams<GetWarehouseToolsType>({
    queryKey: QueryKeys.GET_WAREHOUSE_TOOLS_KEY,
    initialParams: {},
  })

  const [paramsTool, setParamsTool] = useParams<GetToolsType>({
    queryKey: QueryKeys.GET_TOOLS_KEY,
    initialParams: {},
  })

  const warehouseToolsQuery = useQuery<
    WarehouseToolBaseType[],
    ErrorService,
    WarehouseToolBaseType[],
    [string, GetWarehouseToolsType]
  >({
    queryKey: [QueryKeys.GET_WAREHOUSE_TOOLS_KEY, params],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.warehouseEntitiesService.getWarehouseTools(queryKey[1])

      return response
    },
    initialData: [],
  })

  const warehouseToolQuery = useQuery<
    WarehouseToolType,
    ErrorService,
    WarehouseToolType,
    QueryKeysType
  >({
    enabled: Boolean(warehouseToolID),
    queryKey: [QueryKeys.GET_WAREHOUSE_TOOL_KEY, warehouseToolID],
    queryFn: async ({ queryKey }) => {
      if (!queryKey[1]) {
        throw ErrorService.get(ErrorCodes.INVALID_PARAMS)
      }
      const response = await DataRepo.warehouseEntitiesService.getWarehouseToolById(queryKey[1])

      return response
    },
  })

  const warehouseToolDelete = useMutation<void, ErrorService, string>({
    mutationFn: async (data) => {
      await DataRepo.warehouseEntitiesService.deleteWarehouseTool(data)

      setParams({})
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Ocurrió un error al eliminar la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Bodega eliminada',
        message: 'La bodega se eliminó correctamente',
      })

      setWarehouseToolId(null)
      setModalWarehouseToolDelete({ opened: false })
    },
  })

  const removeRMFromWarehouseTool = useMutation<
    void,
    ErrorService,
    DeleteToolsFromWarehouseToolType
  >({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.deleteToolsFromWarehouse(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_TOOL_KEY, warehouseToolID],
        refetchType: 'all',
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_WAREHOUSE_TOOLS_KEY,
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Ocurrió un error al eliminar la materia prima de la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Materia prima eliminada',
        message: 'La materia prima se eliminó correctamente',
      })

      setModalDelete(null)

      handlersSelected.setState([])
    },
  })

  React.useEffect(() => {
    if (!warehouseToolsQuery.data) return

    const firstInHouse = warehouseToolsQuery.data.find((item) => item.inHouse)

    setWarehouseToolId(firstInHouse?.uid ?? null)
  }, [warehouseToolsQuery.data])

  React.useEffect(() => {
    return () => {
      handlersSelected.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoFilterTool = React.useMemo(
    () =>
      onFilterTool<WarehouseToolType['tools'][0]>({
        data: warehouseToolQuery.data?.tools,
        params: paramsTool,
      }),
    [warehouseToolQuery.data?.tools, paramsTool],
  )

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Bodega</Text>
          <Button
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() => setModalForm({ opened: true })}
          >
            Agregar
          </Button>
        </div>

        <Input
          searchable
          data={buildSelectOptions({
            data: warehouseToolsQuery.data,
            value: 'uid',
            label: 'name',
          })}
          loading={isLoadingOrRefetchQuery(warehouseToolsQuery)}
          nothingFoundMessage="No se encontraron bodegas"
          placeholder="Seleccione una bodega para ver las materias primas"
          rightSection={<IconSearch size={18} />}
          typeInput="select"
          value={warehouseToolID}
          onChange={(v) => setWarehouseToolId(v)}
        />

        <Divider className="cd-my-[1rem]" />

        {warehouseToolQuery.data && (
          <div>
            <div className="cd-flex cd-gap-y-[0.25rem] cd-flex-row cd-justify-between cd-items-start cd-align-top">
              <Title className="cd-text-xl cd-grow">{warehouseToolQuery.data.name}</Title>
              <Badge
                className="cd-min-w-[100px]"
                color={warehouseToolQuery.data.inHouse ? 'green' : 'orange'}
                size="lg"
                variant="light"
              >
                {warehouseToolQuery.data.inHouse ? 'Interna' : 'Externa'}
              </Badge>
            </div>
            <Text className="cd-text-base">{warehouseToolQuery.data.description}</Text>

            <div className="cd-mt-[1rem] cd-flex cd-flex-col md:cd-flex-row cd-grow cd-justify-end cd-gap-[1rem]">
              <div className="cd-flex cd-flex-col md:cd-flex-row cd-grow cd-justify-end cd-gap-[1rem] md:cd-basis-[80%]">
                <div className="md:cd-basis-[80%]" />
                <Button
                  fullWidth
                  className="md:cd-basis-[20%]"
                  color="gray"
                  disabled={selectedRows.length > 0}
                  onClick={() => {
                    if (!warehouseToolID) return

                    setModalCommits({
                      opened: true,
                      data: {
                        warehouseToolID,
                        warehouseToolName: warehouseToolQuery.data.name,
                      },
                    })
                  }}
                >
                  Ver registros
                </Button>
              </div>

              <div className="md:cd-basis-[20%] cd-flex md:cd-flex-row cd-gap-[0.5rem] cd-grow">
                <Button
                  fullWidth
                  color="blue"
                  disabled={!warehouseToolQuery.data || selectedRows.length > 0}
                  onClick={() => {
                    if (!warehouseToolQuery.data) return

                    setModalForm({ opened: true, data: warehouseToolQuery.data })
                  }}
                >
                  Editar bodega
                </Button>
                <ActionIcon
                  color="red"
                  disabled={!warehouseToolID || selectedRows.length > 0}
                  size={36}
                  variant="light"
                  onClick={() =>
                    setModalWarehouseToolDelete({ opened: true, data: warehouseToolQuery.data! })
                  }
                >
                  <IconTrash size={18} />
                </ActionIcon>
              </div>
            </div>
          </div>
        )}

        <TableCustom<WarehouseToolType['tools'][0]>
          columns={buildColumns()}
          data={memoFilterTool}
          extraActions={buildExtraOptions()}
          keyId="uid"
          loading={isLoadingOrRefetchQuery(warehouseToolQuery)}
          minWidth={minWidth}
          noDataMessage={
            warehouseToolID
              ? 'No se encontraron materias primas'
              : 'Seleccione una bodega para ver las materias primas'
          }
          placeholderSearch="Buscar materia prima"
          selectedRows={selectedRows}
          onChangeSelected={handlersSelected}
          onDelete={(ids) => {
            if (!warehouseToolQuery.data) return

            const filtered = warehouseToolQuery.data.tools.filter((item) => !ids.includes(item.uid))

            setModalDelete(filtered)
          }}
          // onDetail={(tool) => {
          //   setModalCommits({
          //     opened: true,
          //     data: {
          //       warehouseToolID,
          //       tool,
          //     },
          //   })
          // }}
          onSearch={(query, field) => setParamsTool({ query, field })}
        />

        <WarehouseToolForm
          modal
          data={modalForm.data}
          open={modalForm.opened}
          size="xl"
          onClose={() => setModalForm({ opened: false })}
          onSubmitted={() => null}
        />

        <CommitsTool
          modal
          data={modalCommits.data}
          open={modalCommits.opened}
          size="95%"
          onAddCommitTool={() => {
            if (!warehouseToolID || !warehouseToolQuery.data) return

            setModalCommitForm({
              opened: true,
              data: {
                warehouseToolID,
                toolsWarehouseTool: warehouseToolQuery.data.tools,
                warehouseToolName: warehouseToolQuery.data?.name ?? '',
              },
            })
          }}
          onClose={() => setModalCommits({ opened: false })}
          onEditCommitTool={(commitTool) => {
            if (!warehouseToolID || !warehouseToolQuery.data) return

            setModalCommitForm({
              opened: true,
              data: {
                commitTool,
                warehouseToolID,
                toolsWarehouseTool: warehouseToolQuery.data.tools,
                warehouseToolName: warehouseToolQuery.data?.name ?? '',
              },
            })
          }}
        />

        <MoveTools
          modal
          data={modalToolMove.data}
          open={modalToolMove.opened}
          size="xl"
          onClose={() => setModalToolMove({ opened: false })}
          onSubmitted={() => {
            handlersSelected.setState([])
          }}
        />

        <CommitForm
          modal
          data={modalCommitForm.data}
          open={modalCommitForm.opened}
          size="xl"
          onClose={() => setModalCommitForm({ opened: false })}
        />

        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Eliminar"
          loading={isLoadingMutation(removeRMFromWarehouseTool)}
          opened={Boolean(modalDelete?.length)}
          title="Eliminar materia prima de bodega"
          onCancel={() => setModalDelete(null)}
          onConfirm={() => {
            if (!modalDelete || !warehouseToolQuery.data) return

            removeRMFromWarehouseTool.mutate({
              warehouseToolID: warehouseToolQuery.data.uid,
              toolIDs: modalDelete.map((item) => item.uid),
            })
          }}
        >
          {modalDelete?.length === 1 && (
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar la materia prima{' '}
              <Text inherit className="cd-text-base cd-font-bold" component="span">
                {modalDelete[0].uid} - {modalDelete[0].name}
              </Text>
              ?
            </Text>
          )}
          {modalDelete && modalDelete?.length > 1 && (
            <React.Fragment>
              <Text className="cd-text-base">
                ¿Estás seguro que deseas eliminar las siguientes materias primas?
              </Text>
              <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
                {modalDelete.map((item) => (
                  <List.Item key={item.uid}>
                    <Text className="cd-text-base">
                      {item.uid} - {item.name}
                    </Text>
                  </List.Item>
                ))}
              </List>
            </React.Fragment>
          )}
        </ConfirmationModal>

        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Eliminar"
          loading={isLoadingMutation(warehouseToolDelete)}
          opened={modalWarehouseToolDelete.opened}
          title="Eliminar bodega"
          onCancel={() => setModalWarehouseToolDelete({ opened: false })}
          onConfirm={() => {
            if (!modalWarehouseToolDelete.data) return

            warehouseToolDelete.mutate(modalWarehouseToolDelete.data.uid)
          }}
        >
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la bodega{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalWarehouseToolDelete.data?.uid} - {modalWarehouseToolDelete.data?.name}
            </Text>
            ? Esta bodega es de tipo{' '}
            {
              <Text inherit className="cd-font-bold" component="span">
                {modalWarehouseToolDelete.data?.inHouse ? 'Interna' : 'Externa'}
              </Text>
            }
            .
          </Text>
        </ConfirmationModal>
      </div>
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '5%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '15%', type: 'text', searchable: true },
      {
        key: 'stock',
        label: 'Stock',
        width: '3%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: (item) => <Text c={getColorStock(item.stock, item.alertStock)}>{item.stock}</Text>,
      },
      { key: 'alertStock', label: 'Alerta', width: '3%', type: 'text', align: 'right' },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '3%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
    ] as GenericColumnType<WarehouseToolType['tools'][0]>[]
  }

  function buildExtraOptions() {
    return (
      <React.Fragment>
        <Button
          className="md:cd-basis-[20%]"
          color="blue"
          leftSection={<IconHomeMove size={18} />}
          size={UI.Size}
          variant="filled"
          onClick={() => {
            const tools = warehouseToolQuery.data?.tools.filter(({ uid }) =>
              selectedRows.includes(uid),
            )

            if (!warehouseToolID || !tools?.length) return

            setModalToolMove({
              opened: true,
              data: {
                tools: tools.map((item) => ({
                  ...item,
                  maxQuantity: item.stock,
                })),
                warehouseToolFromID: warehouseToolID,
              },
            })
          }}
        >
          Mover a bodega
        </Button>
      </React.Fragment>
    )
  }
}

export default WarehouseTool
